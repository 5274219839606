Drupal.behaviors.siteHeaderFormatterV1 = (function ($) {
  var $html = $();
  var $body = $();
  var $template = $();
  var hideHeaderOnScroll = false;
  var $gnavHamburger = $();
  var $gnavMainSectionWrapper = $();
  var $gnavMobileSectionsMenu = $();
  var $gnavMainSection = $();
  var $gnavMainSectionContent = $();
  var $gnavMainSectionTrigger = $();
  var $mainSectionTriggerReset = $();
  var $triggerResetLabel = $();
  var $allSectionGroups = $();
  var $allSectionGroupContent = $();
  var $gnavLinks = $();
  var $gnavLinksLabel = $();
  // Calculate desktop for mobile menu stuff.
  var bps = Unison.fetch.all();
  var bp = Unison.fetch.now();
  var isDesktop = parseInt(bp.width, 10) >= parseInt(bps.landscape, 10);
  var position = $(window).scrollTop();
  var behavior = {
    attached: false,
    attach: function (context) {
      if (this.attached) {
        return;
      }
      this.attached = true;
      $html = $('html');
      $body = $('body');
      $template = $('.js-site-header-formatter-v1', context);
      $gnavHamburger = $('.js-gnav-mobile-trigger', $template);
      $gnavMainSectionWrapper = $('.js-site-header-formatter__sections', $template);
      $gnavMobileSectionsMenu = $(
        '.js-site-header-formatter__mobile-menu-header',
        $gnavMainSectionWrapper
      );
      $gnavMainSection = $('.js-header-nav-section__section-v1', $gnavMainSectionWrapper);
      $gnavMainSectionContent = $('.js-header-nav-section__section-content', $gnavMainSection);
      $gnavMainSectionTrigger = $('.js-header-nav-section__section-label', $gnavMainSection);
      $mainSectionTriggerReset = $('#gnav_section_trigger_reset', $template);
      $triggerResetLabel = $('.js-gnav-section-trigger-reset', $template);
      // These elements are in gnav_section_formatter_v1, we collapse them too when gnav is closed.
      $allSectionGroups = $('.js-gnav-section-formatter-v1', $template);
      $allSectionGroupContent = $('.js-gnav-section-formatter__content-wrapper', $allSectionGroups);
      // These elements are in gnav_links_v1.
      $gnavLinks = $gnavMainSectionWrapper.find('.js-gnav-links-trigger');
      $gnavLinksLabel = $gnavMainSectionWrapper.find('.js-gnav-links-header-label');
      hideHeaderOnScroll = $template.hasClass('hide-site-header-on-scroll');

      $mainSectionTriggerReset.change(function () {
        if (this.checked) {
          $html.toggleClass('active-utility-overlay', false);
        }
      });

      if ($.cookie('hide_header_offer_banner')) {
        $template.toggleClass('header-offers-banner-hidden', true);
      }

      function _updateNavStatus() {
        var scroll = $(window).scrollTop();
        var templateHeight = $template.outerHeight(true);

        // If the user scroll down, hide the Nav
        if (scroll <= position) {
          $body.toggleClass('site-header-formatter--hidden', false);
        } else if (scroll > 5) {
          $body.toggleClass('site-header-formatter--hidden', true);
          $body.toggleClass('site-header-formatter--sticky', true);
        }

        if (scroll <= templateHeight) {
          $body.toggleClass('site-header-formatter--sticky', false);
        }

        position = scroll;
      }

      // close the nav.
      function _closeGnav(keepActive) {
        $html.toggleClass('active-gnav', !!keepActive);
        $gnavMainSectionWrapper.attr('aria-expanded', !!keepActive);

        // Reset Header Nav Section elements state.
        $gnavMainSection.attr('aria-expanded', 'false');
        $gnavMainSectionContent.attr('aria-hidden', 'true');

        // Reset Section Formatter elements state.
        $allSectionGroups.attr('aria-expanded', 'false');
        $allSectionGroupContent.attr('aria-hidden', 'true');

        // Reset Links state.
        $gnavLinks.prop('checked', false).change();
        $gnavLinks.siblings('.gnav-links__content').attr('aria-expanded', isDesktop);

        if (!keepActive) {
          $mainSectionTriggerReset.prop('checked', true);
          $gnavMainSectionWrapper.off('keydown.restrictNavigation');
          $gnavMainSectionContent.off('keydown.restrictNavigation');
        }
      }

      function _gnavHeaderTrigger() {
        if (isDesktop) {
          $gnavMainSectionTrigger.off('mouseenter').on('mouseenter', function (e) {
            var $self = $(this);

            _gnavPcTrigger($self, e);
          });
        } else {
          $gnavMainSectionTrigger.off('mouseenter');
          $gnavMainSectionTrigger.off('click touchstart').on('click touchstart', function (e) {
            var $self = $(this);

            _gnavMobileTrigger($self, e);
          });
        }
      }

      function _gnavPcTrigger($self, e) {
        var isKeyEvent = e.type === 'enter' ? true : false;
        var $parent = $self.parents('.js-header-nav-section__section-v1');
        var $input = $parent.prev();
        var $content = $parent.find('.js-header-nav-section__section-content');
        var $subNav = $content.find('.js-gnav-section-formatter-v1').first();
        var $subNavcontent = $subNav.find('.js-gnav-section-formatter__content-wrapper');
        var keepActive = $content.length ? true : false;

        if ($parent.attr('aria-expanded') === 'true') {
          return;
        }
        _closeGnav(keepActive);
        if ($content.length) {
          e.preventDefault();
          $input.prop('checked', true);
          $parent.attr('aria-expanded', 'true');
          $content.attr('aria-hidden', 'false');
          $gnavMainSectionWrapper.attr('aria-expanded', 'true');
          $subNav.attr('aria-expanded', 'true');
          $subNavcontent.attr('aria-hidden', 'false');

          if (isKeyEvent) {
            _restrictNavigation($content);
          }
        }
      }

      function _gnavMobileTrigger($self, e) {
        var $parent = $self.parents('.js-header-nav-section__section-v1');
        var $input = $parent.prev();
        var $content = $parent.find('.js-header-nav-section__section-content');
        var $subNav = $parent.find('.js-gnav-section-formatter-v1');

        _closeGnav(true);
        if (!$content.length) {
          return;
        }
        if (isDesktop === false) {
          e.preventDefault();
        }
        $input.prop('checked', true);
        $html.toggleClass('active-gnav', true);
        $gnavMainSectionWrapper.attr('aria-expanded', 'true');
        $subNav.attr('aria-expanded', 'true');
        $parent.attr('aria-expanded', 'true');
        $content.attr('aria-hidden', 'false');
      }

      // for retaining Tab Focus within Container given.
      function _restrictNavigation($element) {
        $element.off('keydown.restrictNavigation').on('keydown.restrictNavigation', function (e) {
          var $this = $(this);
          var key = e.keyCode ? e.keyCode : e.which;
          var $closeBtn = isDesktop ? $triggerResetLabel.first() : $gnavHamburger.first();
          var $tabbables = $this.find(':tabbable');
          var $first = $tabbables.filter(':first');
          var $last = $tabbables.filter(':last');
          var $target = $(e.target);

          // no  Tab
          if (key !== 9 || e.isDefaultPrevented()) {
            if (key === 27) {
              // Esc
              $closeBtn.trigger('enter');
            } else if (key === 35) {
              // End
              $last.focus();
            } else if (key === 36) {
              // Home
              $first.focus();
            }

            return;
          }

          if (($target[0] === $last[0] || $target[0] === this) && !e.shiftKey) {
            $first.focus();
            e.preventDefault();
          } else if (($target[0] === $first[0] || $target[0] === this) && e.shiftKey) {
            $last.focus();
            e.preventDefault();
          }
        });
      }

      function _toggleMobileMenu(e) {
        var isKeyEvent = e && e.type === 'enter' ? true : false;

        // If mobile content is open then reset radio buttom for PC.
        $mainSectionTriggerReset.prop('checked', true);
        $html.toggleClass('active-gnav');
        if ($html.hasClass('active-gnav')) {
          $gnavMainSectionWrapper.attr('aria-expanded', 'true');
          if (isKeyEvent) {
            _restrictNavigation($gnavMainSectionWrapper);
          }
        } else {
          _closeGnav();
        }
      }

      function _initKeyEvents(event) {
        var key = event.keyCode ? event.keyCode : event.which;
        var targetEvent;

        if (key === 9) {
          // Tab
          targetEvent = $.extend({}, event, { type: 'tab' });

          return $(event.target).trigger(targetEvent);
        }

        if (key === 13) {
          // Enter
          targetEvent = $.extend({}, event, { type: 'enter' });

          return $(event.target).trigger(targetEvent);
        }
        if (key === 27) {
          // Esc
          $(document).trigger('check_reset_gnav_radio_buttom');
        }
      }

      $template.on('keydown', function (event) {
        _initKeyEvents(event);
      });

      // Subnav updates using js only.
      if (isDesktop) {
        $gnavMainSectionWrapper.attr('aria-expanded', 'true');
        $gnavLinks.siblings('.gnav-links__content').attr('aria-expanded', 'true');
      }

      if (hideHeaderOnScroll) {
        $(window)
          .off('scroll.gnavHeaderBlock')
          .on('scroll.gnavHeaderBlock', _.throttle(_updateNavStatus, 100));
        _updateNavStatus();
      }

      _gnavHeaderTrigger();

      Unison.on('change', function () {
        bp = Unison.fetch.now();
        isDesktop = parseInt(bp.width, 10) >= parseInt(bps.landscape, 10);
        _closeGnav();
        _gnavHeaderTrigger();
      });

      $triggerResetLabel.off('click').on('click', function () {
        _closeGnav();
        $html.toggleClass('active-utility-overlay', false);
      });

      // Mobile only icon takes user back to main gnav sections display and collapses all open sections
      $gnavMobileSectionsMenu
        .off('click')
        .on('click', function () {
          _closeGnav(true);
        })
        .on('enter', function () {
          var targetId = $(this).attr('for');
          var $input = $('#' + targetId);
          var isChecked = $input.is(':checked');

          $input.prop('checked', !isChecked);
          _closeGnav(true);
        });

      $gnavHamburger.off('click').on('click', function () {
        _toggleMobileMenu();
      });

      $gnavLinksLabel.off('click').on('click', function () {
        var targetId = $(this).attr('for');
        var $input = $('#' + targetId);
        var isChecked = $input.is(':checked');

        $input.siblings('.gnav-links__content').attr('aria-expanded', !isChecked);
      });

      /*
      Close Nav dropdown when clicking outside of it
      */
      $html.on('click', function (event) {
        if (
          isDesktop &&
          $html.hasClass('active-gnav') &&
          !$(event.target).parents('.site-header-formatter__header').length
        ) {
          _closeGnav();
        }
      });

      /*
      Close Nav dropdown (after a short delay) when moving mouse out of nav section
      */
      $allSectionGroupContent.on('mouseleave', function () {
        if (isDesktop && $html.hasClass('active-gnav')) {
          setTimeout(_closeGnav, 500);
        }
      });

      $(document).on('hide_header_offer_banner', function () {
        $template.toggleClass('header-offers-banner-hidden', true);
      });

      $(document).on('check_reset_gnav_radio_buttom', function () {
        // Reset Header Nav Section elements state.
        $gnavMainSection.attr('aria-expanded', 'false');
        $mainSectionTriggerReset.prop('checked', true);
      });
    }
  };

  return behavior;
})(jQuery);
